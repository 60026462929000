<!--
File: HeaderBar.vue
Date: 2024/2/9
Author: LiangChengHQR
-->
<template>
  <div class="bar">
    <div class="left" @click="this.$router.push('/')">
      <el-icon :size="30" color="white">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><rect x="48" y="96" width="416" height="320" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="384" y="336" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="384" y="256" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="384" y="176" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="384" y="96" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="48" y="336" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="48" y="256" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="48" y="176" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="48" y="96" width="80" height="80" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="128" y="96" width="256" height="160" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect><rect x="128" y="256" width="256" height="160" rx="28" ry="28" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect></svg>
      </el-icon>
      <div class="title">{{title}}</div>
    </div>

    <div class="middle">
      <el-input
          @keydown="(e)=>{e.keyCode === 13 && search()}" placeholder="搜索你想看的内容..."
          style="height: 70%; width: 90%" clearable v-model="searchInput">
        <template #prepend>
          <el-icon size="20" @click="search" style="margin-left: -10px; margin-right: -10px; cursor: pointer">
            <svg t="1707836649924" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4263" width="512" height="512"><path d="M907.4 906.4c-29 29-76 29-105 0L657.7 761.7c-57.6 38.4-126.8 60.9-201.3 60.9-200.5 0-363.1-162.6-363.1-363.2S255.9 96.2 456.5 96.2s363.2 162.6 363.2 363.2c0 72.4-21.2 139.9-57.7 196.5l145.5 145.5c28.9 29 28.9 76-0.1 105zM456.4 231C330.3 231 228 333.3 228 459.4c0 126.1 102.3 228.4 228.4 228.4s228.4-102.3 228.4-228.4C684.9 333.3 582.6 231 456.4 231z m118.1 379.4c-1.4 2.1-3.5 3.6-6.2 4.2-5.5 1.3-11.1-2.2-12.3-7.7-1.2-5.2 1.7-10.3 6.7-12 59-46.5 80.8-126.4 53.6-196.1-0.7-1.4-1-2.9-1-4.5 0-5.7 4.6-10.3 10.3-10.3 4.2 0 7.8 2.5 9.4 6.1h0.1c30.7 78.3 6.1 168.4-60.6 220.3z" fill="#6182c7" p-id="4264"></path></svg>
          </el-icon>
        </template>
      </el-input>
    </div>

    <div class="right">
      <div v-if="isLargeScreen">
<!--        <el-button @click="clickTv" text color="white" style="font-weight: bold;">-->
<!--          <el-icon :size="23" color="black" style="margin-right: 4px">-->
<!--            <svg t="1707919051188" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4447" width="256" height="256"><path d="M919.9 221.6H104.1c-5.5 0-10 4.5-10 10v492c0 5.5 4.5 10 10 10H502V780H290.1c-5.5 0-10 4.5-10 10s4.5 10 10 10h443.8c5.5 0 10-4.5 10-10s-4.5-10-10-10H522v-46.4h397.9c5.5 0 10-4.5 10-10v-492c0-5.5-4.5-10-10-10z m-10 492H114.1v-472h795.8v472z" fill="#2c2c2c" p-id="4448"></path><path d="M144.1 697.3h735.8c5.5 0 10-4.5 10-10V267.8c0-5.5-4.5-10-10-10H144.1c-5.5 0-10 4.5-10 10v419.5c0 5.6 4.5 10 10 10z m10-419.5h715.8v399.5H154.1V277.8z" fill="#2c2c2c" p-id="4449"></path><path d="M647.4 419.2c-4.3-3.5-10.6-2.8-14.1 1.5l-71.7 88.8-40.6-86.8c0-0.1-0.1-0.1-0.1-0.2-0.1-0.2-0.2-0.4-0.3-0.5-0.1-0.1-0.2-0.3-0.2-0.4-0.1-0.1-0.2-0.3-0.3-0.4-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-0.3-0.4-0.4-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.2-0.1-0.1-0.3-0.2-0.4-0.3-0.1-0.1-0.2-0.2-0.4-0.2-0.1-0.1-0.3-0.2-0.4-0.3-0.1-0.1-0.3-0.2-0.4-0.2-0.1-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.3-0.1-0.5-0.2-0.1-0.1-0.3-0.1-0.4-0.1-0.2-0.1-0.3-0.1-0.5-0.2-0.2 0-0.3-0.1-0.5-0.1s-0.3-0.1-0.5-0.1-0.4-0.1-0.5-0.1H382.9c-5.5 0-10 4.5-10 10s4.5 10 10 10h52.7l-15.2 89.6c-0.9 5.4 2.7 10.6 8.2 11.5 0.6 0.1 1.1 0.1 1.7 0.1 4.8 0 9-3.5 9.8-8.3l15.7-92.9h49.7l44.8 95.5c1.5 3.1 4.4 5.3 7.9 5.7 0.4 0 0.8 0.1 1.2 0.1 3 0 5.9-1.4 7.8-3.7L649 433.5c3.4-4.6 2.7-10.9-1.6-14.3zM354.3 303.1l-178 120.5a9.99 9.99 0 0 0-2.7 13.9c1.9 2.9 5.1 4.4 8.3 4.4 1.9 0 3.9-0.6 5.6-1.7l178-120.5c4.6-3.1 5.8-9.3 2.7-13.9a9.99 9.99 0 0 0-13.9-2.7zM227.8 354.1c1.9 0 3.9-0.6 5.6-1.7l48.3-32.7c4.6-3.1 5.8-9.3 2.7-13.9a9.99 9.99 0 0 0-13.9-2.7l-48.3 32.7a9.99 9.99 0 0 0-2.7 13.9c2 2.9 5.1 4.4 8.3 4.4z" fill="#2c2c2c" p-id="4450"></path></svg></el-icon>-->
<!--          电视-->
<!--        </el-button>-->
        <el-button @click="clickCategory('1')" text color="white" style="font-weight: bold;">
          <el-icon :size="23" color="black" style="margin-right: 4px">
            <svg t="1707531706710" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2698" width="256" height="256"><path d="M844.832 885.344c-30.485333-20.714667-77.781333-18.965333-144.053333 9.386667A425.024 425.024 0 0 1 512 938.666667C276.362667 938.666667 85.333333 747.637333 85.333333 512S276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667a425.013333 425.013333 0 0 1-44.405334 189.717333 32 32 0 0 1-57.301333-28.490666A361.013333 361.013333 0 0 0 874.666667 512c0-200.298667-162.368-362.666667-362.666667-362.666667S149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667c56.704 0 111.488-13.013333 161.12-37.653334l1.6-0.746666c84.746667-36.437333 154.176-39.125333 206.08-3.861334a32 32 0 1 1-35.968 52.938667zM512 416a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m160 160a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM512 736a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM352 576a64 64 0 1 1 0-128 64 64 0 0 1 0 128z" fill="#000000" p-id="2699"></path></svg>
          </el-icon>
          电影
        </el-button>
        <el-button @click="clickCategory('2')" text color="white" style="font-weight: bold;">
          <el-icon :size="22" color="black" style="margin-right: 4px; margin-top: -3px">
            <svg t="1707531967257" class="icon" viewBox="0 0 1246 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9477" width="256" height="256"><path d="M1102.336 873.642667a34.645333 34.645333 0 0 1-34.474667 34.474666H435.029333a34.645333 34.645333 0 0 1-34.474666-34.474666V357.802667c0-18.944 15.530667-34.474667 34.474666-34.474667h632.832c18.944 0 34.474667 15.530667 34.474667 34.474667v515.84z m-760.32-464.128c0 16.896-13.738667 30.72-30.72 30.72h-55.466667a30.890667 30.890667 0 0 1-30.72-30.72v-55.466667c0-16.896 13.824-30.72 30.72-30.72h55.466667c16.981333 0 30.72 13.824 30.72 30.72v55.466667z m0 175.445333c0 16.896-13.738667 30.72-30.72 30.72h-55.466667a30.890667 30.890667 0 0 1-30.72-30.72v-55.466667c0-16.896 13.824-30.72 30.72-30.72h55.466667c16.981333 0 30.72 13.824 30.72 30.72v55.466667zM1134.762667 206.336H222.037333A84.906667 84.906667 0 0 0 137.386667 290.986667v620.288c0 46.506667 38.058667 84.565333 84.650666 84.565333h912.64a84.906667 84.906667 0 0 0 84.650667-84.565333V290.986667a84.906667 84.906667 0 0 0-84.650667-84.650667z" fill="#2c2c2c" p-id="9478"></path><path d="M833.536 257.194667L682.922667 75.946667A28.501333 28.501333 0 0 1 686.592 36.010667L687.786667 34.986667A28.501333 28.501333 0 0 1 727.893333 38.741333l150.613334 181.077334a28.501333 28.501333 0 0 1-3.669334 40.106666l-1.28 0.938667a28.501333 28.501333 0 0 1-40.106666-3.669333" fill="#2c2c2c" p-id="9479"></path><path d="M961.792 257.194667L1112.405333 75.946667a28.501333 28.501333 0 0 0-3.669333-40.021334L1107.456 34.986667A28.501333 28.501333 0 0 0 1067.52 38.741333L916.821333 219.733333a28.501333 28.501333 0 0 0 3.669334 40.106667l1.28 0.938667A28.501333 28.501333 0 0 0 961.706667 257.194667L768 158.464" fill="#2c2c2c" p-id="9480"></path></svg>
          </el-icon>
          剧集
        </el-button>
        <el-button @click="clickCategory('3')" text color="white" style="font-weight: bold;">
          <el-icon :size="22" color="black" style="margin-right: 4px">
            <svg t="1707920222542" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5516" width="256" height="256"><path d="M648.64 544a228.48 228.48 0 1 1 161.6-66.88A227.84 227.84 0 0 1 648.64 544z m0-408.96a180.48 180.48 0 1 0 128 52.8 180 180 0 0 0-128-53.12z" fill="#2c2c2c" p-id="5517"></path><path d="M300.16 796.16h-9.28c-81.6-5.44-112-77.92-116.8-115.52l-1.28-9.6 245.28-325.76a24 24 0 1 1 38.24 28.96L224 682.88a80.96 80.96 0 0 0 61.92 64L594.88 502.4A24 24 0 1 1 624 540z" fill="#2c2c2c" p-id="5518"></path><path d="M472.96 642.08a23.84 23.84 0 0 1-7.52-1.28c-129.44-43.2-144-140.48-145.12-144a24 24 0 0 1 48-6.88c0 3.36 12.96 72.64 112 105.92a24 24 0 0 1-7.52 46.72z m-52.8 295.2A24 24 0 0 1 398.72 924c-21.28-42.72-42.56-37.76-97.6-12.8-37.44 16.96-80 36.16-119.04 12a70.72 70.72 0 0 1-34.56-51.2c-8.48-59.04 53.6-129.6 60.8-137.44a24 24 0 0 1 35.68 32c-20.96 23.36-52.8 70.72-48.8 98.4a23.04 23.04 0 0 0 12.16 17.12c16 10.08 40 0 74.08-14.88 46.4-20.96 116.48-52.8 160 35.04A24 24 0 0 1 420 936.96z" fill="#2c2c2c" p-id="5519"></path></svg></el-icon>
          综艺
        </el-button>
        <el-button @click="clickCategory('4')" text color="white" style="font-weight: bold;">
          <el-icon :size="22" color="black" style="margin-right: 4px">
            <svg t="1707531845905" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5664" width="256" height="256"><path d="M662.016 593.408c-5.632 0-10.752 2.048-15.36 5.12-38.4 30.208-84.992 46.592-134.656 46.592s-96.256-16.384-134.656-46.592c-4.096-3.584-9.728-5.12-15.36-5.12-23.04 0-33.28 29.184-14.848 43.52 45.568 35.84 102.912 57.344 164.864 57.344s119.808-21.504 164.864-57.344c18.432-14.336 8.192-43.52-14.848-43.52z" p-id="5665"></path><path d="M510.464 874.496c-186.368 0-336.384-151.552-336.384-336.384 0-26.624 2.56-51.2 8.192-76.288C124.416 435.712 87.04 378.88 87.04 313.344c0-90.112 73.728-163.84 163.84-163.84 56.832 0 108.544 29.184 137.728 75.264 38.912-15.36 80.384-23.552 123.392-23.552s84.48 8.192 123.392 23.552c29.184-47.104 80.384-75.264 137.728-75.264 90.112 0 163.84 73.728 163.84 163.84 0 65.536-38.912 122.368-95.744 148.48 5.632 25.088 8.192 51.2 8.192 76.288-2.56 184.832-152.576 336.384-338.944 336.384zM249.344 204.8C189.44 204.8 140.8 253.44 140.8 313.344c0 50.176 33.28 91.648 81.92 103.936 7.168 1.536 13.824 7.168 17.92 13.824 4.096 7.168 4.096 15.36 2.56 22.016-8.192 27.648-13.824 56.832-13.824 86.016 0 155.648 126.464 280.576 280.576 280.576s280.576-128 280.576-282.112c0-29.184-4.096-58.368-13.824-86.016-2.56-7.168-1.536-15.36 2.56-22.016 4.096-7.168 9.728-11.264 17.92-13.824 47.104-12.288 81.92-55.808 81.92-103.936 0-59.904-48.64-108.544-108.544-108.544-43.008 0-83.456 26.624-99.84 66.56-2.56 7.168-8.192 12.288-15.36 15.36-7.168 2.56-15.36 2.56-22.016-1.536-38.912-19.456-80.384-29.184-123.392-29.184s-84.48 9.728-123.392 29.184c-7.168 2.56-15.36 4.096-22.016 1.536-7.168-2.56-12.288-8.192-15.36-15.36C332.8 229.888 293.888 204.8 249.344 204.8z" p-id="5666"></path></svg>
          </el-icon>
          动漫
        </el-button>
        <el-button @click="clickHistory" text color="white" style="font-weight: bold;">
          <el-icon :size="20" color="black" style="margin-right: 4px">
            <svg t="1707532081623" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10576" width="256" height="256"><path d="M992 541.2c1.5 264.1-210.2 479-473.1 482.7C251.2 1027.7 33 813.5 32 545.7c-0.1-14.5 0.5-28.9 1.8-43.1 4.2-49 45-86.7 94.1-86.7 10.7 0 18.3 10.3 15.3 20.6C133.1 471.2 128 507.2 128 544c0 51.9 10.1 102.2 30.1 149.4 19.3 45.7 47 86.8 82.3 122.1s76.4 63 122.1 82.3c47.3 20 97.6 30.1 149.4 30.1s102.2-10.1 149.4-30.1c45.7-19.3 86.8-47 122.1-82.3s63-76.4 82.3-122.1c20-47.3 30.1-97.6 30.1-149.4s-10.1-102.2-30.1-149.4c-19.3-45.7-47-86.8-82.3-122.1s-76.4-63-122.1-82.3c-47.3-20-97.6-30.1-149.4-30.1s-102.2 10.1-149.4 30.1c-17.9 7.6-35 16.4-51.4 26.5-3.4 2.1-1.9 7.4 2.1 7.4h63.2c4.7 0 9.3 2.1 12.3 5.8l69.7 83.7c2.2 2.6 0.3 6.6-3.1 6.6H256c-70.7 0-128-57.3-128-128V9.7c0-3.6 4.3-5.3 6.8-2.8l84.5 84.5c3 3 4.7 7.1 4.7 11.3v49.4c0 3.3 3.7 5.2 6.3 3.2C309.6 97.8 407.1 63.9 512.5 64c264.4 0.2 478 212.8 479.5 477.2z" p-id="10577" fill="#2c2c2c"></path><path d="M640 356v220c0 53-43 96-96 96H308c-2.2 0-4-1.8-4-4v-88c0-2.2 1.8-4 4-4h204c17.7 0 32-14.3 32-32V356c0-2.2 1.8-4 4-4h88c2.2 0 4 1.8 4 4z" p-id="10578" fill="#2c2c2c"></path></svg>
          </el-icon>
          观看历史
        </el-button>
      </div>
      <div v-else>
        <el-dropdown>
          <el-button text style="color: black; font-weight: bold; font-size: 15px">
            菜单
            <el-icon color="black" :size="15" style="margin-left: 5px">
              <svg t="1707543035230" class="icon" viewBox="0 0 1048 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11599" width="256" height="256"><path d="M565.514 752.186c0.393-0.394 0.491-0.935 0.861-1.326l431.062-392.233c23.201-23.692 23.471-61.882 0.566-85.254-22.906-23.374-60.284-23.126-83.511 0.589l-390.168 355.025-390.933-355.739c-23.224-23.691-60.826-23.716-84.025-0.049-23.174 23.692-23.151 62.079 0.049 85.795l432.096 393.168c23.199 23.692 60.827 23.714 84.001 0.024z" fill="#272536" p-id="11600"></path></svg>
            </el-icon>
          </el-button>
          <template #dropdown>
              <el-dropdown-menu>
<!--                <el-dropdown-item @click="clickTv">-->
<!--                  <el-icon :size="23" color="black" style="margin-right: 4px">-->
<!--                    <svg t="1707919051188" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4447" width="256" height="256"><path d="M919.9 221.6H104.1c-5.5 0-10 4.5-10 10v492c0 5.5 4.5 10 10 10H502V780H290.1c-5.5 0-10 4.5-10 10s4.5 10 10 10h443.8c5.5 0 10-4.5 10-10s-4.5-10-10-10H522v-46.4h397.9c5.5 0 10-4.5 10-10v-492c0-5.5-4.5-10-10-10z m-10 492H114.1v-472h795.8v472z" fill="#2c2c2c" p-id="4448"></path><path d="M144.1 697.3h735.8c5.5 0 10-4.5 10-10V267.8c0-5.5-4.5-10-10-10H144.1c-5.5 0-10 4.5-10 10v419.5c0 5.6 4.5 10 10 10z m10-419.5h715.8v399.5H154.1V277.8z" fill="#2c2c2c" p-id="4449"></path><path d="M647.4 419.2c-4.3-3.5-10.6-2.8-14.1 1.5l-71.7 88.8-40.6-86.8c0-0.1-0.1-0.1-0.1-0.2-0.1-0.2-0.2-0.4-0.3-0.5-0.1-0.1-0.2-0.3-0.2-0.4-0.1-0.1-0.2-0.3-0.3-0.4-0.1-0.1-0.2-0.3-0.3-0.4l-0.3-0.3-0.4-0.4-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.2-0.1-0.1-0.3-0.2-0.4-0.3-0.1-0.1-0.2-0.2-0.4-0.2-0.1-0.1-0.3-0.2-0.4-0.3-0.1-0.1-0.3-0.2-0.4-0.2-0.1-0.1-0.3-0.1-0.4-0.2-0.2-0.1-0.3-0.1-0.5-0.2-0.1-0.1-0.3-0.1-0.4-0.1-0.2-0.1-0.3-0.1-0.5-0.2-0.2 0-0.3-0.1-0.5-0.1s-0.3-0.1-0.5-0.1-0.4-0.1-0.5-0.1H382.9c-5.5 0-10 4.5-10 10s4.5 10 10 10h52.7l-15.2 89.6c-0.9 5.4 2.7 10.6 8.2 11.5 0.6 0.1 1.1 0.1 1.7 0.1 4.8 0 9-3.5 9.8-8.3l15.7-92.9h49.7l44.8 95.5c1.5 3.1 4.4 5.3 7.9 5.7 0.4 0 0.8 0.1 1.2 0.1 3 0 5.9-1.4 7.8-3.7L649 433.5c3.4-4.6 2.7-10.9-1.6-14.3zM354.3 303.1l-178 120.5a9.99 9.99 0 0 0-2.7 13.9c1.9 2.9 5.1 4.4 8.3 4.4 1.9 0 3.9-0.6 5.6-1.7l178-120.5c4.6-3.1 5.8-9.3 2.7-13.9a9.99 9.99 0 0 0-13.9-2.7zM227.8 354.1c1.9 0 3.9-0.6 5.6-1.7l48.3-32.7c4.6-3.1 5.8-9.3 2.7-13.9a9.99 9.99 0 0 0-13.9-2.7l-48.3 32.7a9.99 9.99 0 0 0-2.7 13.9c2 2.9 5.1 4.4 8.3 4.4z" fill="#2c2c2c" p-id="4450"></path></svg></el-icon>-->
<!--                  电视-->
<!--                </el-dropdown-item>-->
                <el-dropdown-item @click="clickCategory('1')">
                  <el-icon :size="23" color="black" style="margin-right: 4px">
                    <svg t="1707531706710" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2698" width="256" height="256"><path d="M844.832 885.344c-30.485333-20.714667-77.781333-18.965333-144.053333 9.386667A425.024 425.024 0 0 1 512 938.666667C276.362667 938.666667 85.333333 747.637333 85.333333 512S276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667a425.013333 425.013333 0 0 1-44.405334 189.717333 32 32 0 0 1-57.301333-28.490666A361.013333 361.013333 0 0 0 874.666667 512c0-200.298667-162.368-362.666667-362.666667-362.666667S149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667c56.704 0 111.488-13.013333 161.12-37.653334l1.6-0.746666c84.746667-36.437333 154.176-39.125333 206.08-3.861334a32 32 0 1 1-35.968 52.938667zM512 416a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m160 160a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM512 736a64 64 0 1 1 0-128 64 64 0 0 1 0 128zM352 576a64 64 0 1 1 0-128 64 64 0 0 1 0 128z" fill="#000000" p-id="2699"></path></svg>
                  </el-icon>
                  电影
                </el-dropdown-item>
                <el-dropdown-item @click="clickCategory('2')">
                  <el-icon :size="22" color="black" style="margin-right: 4px; margin-top: -3px">
                    <svg t="1707531967257" class="icon" viewBox="0 0 1246 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9477" width="256" height="256"><path d="M1102.336 873.642667a34.645333 34.645333 0 0 1-34.474667 34.474666H435.029333a34.645333 34.645333 0 0 1-34.474666-34.474666V357.802667c0-18.944 15.530667-34.474667 34.474666-34.474667h632.832c18.944 0 34.474667 15.530667 34.474667 34.474667v515.84z m-760.32-464.128c0 16.896-13.738667 30.72-30.72 30.72h-55.466667a30.890667 30.890667 0 0 1-30.72-30.72v-55.466667c0-16.896 13.824-30.72 30.72-30.72h55.466667c16.981333 0 30.72 13.824 30.72 30.72v55.466667z m0 175.445333c0 16.896-13.738667 30.72-30.72 30.72h-55.466667a30.890667 30.890667 0 0 1-30.72-30.72v-55.466667c0-16.896 13.824-30.72 30.72-30.72h55.466667c16.981333 0 30.72 13.824 30.72 30.72v55.466667zM1134.762667 206.336H222.037333A84.906667 84.906667 0 0 0 137.386667 290.986667v620.288c0 46.506667 38.058667 84.565333 84.650666 84.565333h912.64a84.906667 84.906667 0 0 0 84.650667-84.565333V290.986667a84.906667 84.906667 0 0 0-84.650667-84.650667z" fill="#2c2c2c" p-id="9478"></path><path d="M833.536 257.194667L682.922667 75.946667A28.501333 28.501333 0 0 1 686.592 36.010667L687.786667 34.986667A28.501333 28.501333 0 0 1 727.893333 38.741333l150.613334 181.077334a28.501333 28.501333 0 0 1-3.669334 40.106666l-1.28 0.938667a28.501333 28.501333 0 0 1-40.106666-3.669333" fill="#2c2c2c" p-id="9479"></path><path d="M961.792 257.194667L1112.405333 75.946667a28.501333 28.501333 0 0 0-3.669333-40.021334L1107.456 34.986667A28.501333 28.501333 0 0 0 1067.52 38.741333L916.821333 219.733333a28.501333 28.501333 0 0 0 3.669334 40.106667l1.28 0.938667A28.501333 28.501333 0 0 0 961.706667 257.194667L768 158.464" fill="#2c2c2c" p-id="9480"></path></svg>
                  </el-icon>
                  剧集
                </el-dropdown-item>
                <el-dropdown-item @click="clickCategory('3')">
                  <el-icon :size="22" color="black" style="margin-right: 4px; margin-top: -3px">
                    <svg t="1707920222542" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5516" width="256" height="256"><path d="M648.64 544a228.48 228.48 0 1 1 161.6-66.88A227.84 227.84 0 0 1 648.64 544z m0-408.96a180.48 180.48 0 1 0 128 52.8 180 180 0 0 0-128-53.12z" fill="#2c2c2c" p-id="5517"></path><path d="M300.16 796.16h-9.28c-81.6-5.44-112-77.92-116.8-115.52l-1.28-9.6 245.28-325.76a24 24 0 1 1 38.24 28.96L224 682.88a80.96 80.96 0 0 0 61.92 64L594.88 502.4A24 24 0 1 1 624 540z" fill="#2c2c2c" p-id="5518"></path><path d="M472.96 642.08a23.84 23.84 0 0 1-7.52-1.28c-129.44-43.2-144-140.48-145.12-144a24 24 0 0 1 48-6.88c0 3.36 12.96 72.64 112 105.92a24 24 0 0 1-7.52 46.72z m-52.8 295.2A24 24 0 0 1 398.72 924c-21.28-42.72-42.56-37.76-97.6-12.8-37.44 16.96-80 36.16-119.04 12a70.72 70.72 0 0 1-34.56-51.2c-8.48-59.04 53.6-129.6 60.8-137.44a24 24 0 0 1 35.68 32c-20.96 23.36-52.8 70.72-48.8 98.4a23.04 23.04 0 0 0 12.16 17.12c16 10.08 40 0 74.08-14.88 46.4-20.96 116.48-52.8 160 35.04A24 24 0 0 1 420 936.96z" fill="#2c2c2c" p-id="5519"></path></svg></el-icon>
                  综艺
                </el-dropdown-item>
                <el-dropdown-item @click="clickCategory('4')">
                  <el-icon :size="22" color="black" style="margin-right: 4px">
                    <svg t="1707531845905" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5664" width="256" height="256"><path d="M662.016 593.408c-5.632 0-10.752 2.048-15.36 5.12-38.4 30.208-84.992 46.592-134.656 46.592s-96.256-16.384-134.656-46.592c-4.096-3.584-9.728-5.12-15.36-5.12-23.04 0-33.28 29.184-14.848 43.52 45.568 35.84 102.912 57.344 164.864 57.344s119.808-21.504 164.864-57.344c18.432-14.336 8.192-43.52-14.848-43.52z" p-id="5665"></path><path d="M510.464 874.496c-186.368 0-336.384-151.552-336.384-336.384 0-26.624 2.56-51.2 8.192-76.288C124.416 435.712 87.04 378.88 87.04 313.344c0-90.112 73.728-163.84 163.84-163.84 56.832 0 108.544 29.184 137.728 75.264 38.912-15.36 80.384-23.552 123.392-23.552s84.48 8.192 123.392 23.552c29.184-47.104 80.384-75.264 137.728-75.264 90.112 0 163.84 73.728 163.84 163.84 0 65.536-38.912 122.368-95.744 148.48 5.632 25.088 8.192 51.2 8.192 76.288-2.56 184.832-152.576 336.384-338.944 336.384zM249.344 204.8C189.44 204.8 140.8 253.44 140.8 313.344c0 50.176 33.28 91.648 81.92 103.936 7.168 1.536 13.824 7.168 17.92 13.824 4.096 7.168 4.096 15.36 2.56 22.016-8.192 27.648-13.824 56.832-13.824 86.016 0 155.648 126.464 280.576 280.576 280.576s280.576-128 280.576-282.112c0-29.184-4.096-58.368-13.824-86.016-2.56-7.168-1.536-15.36 2.56-22.016 4.096-7.168 9.728-11.264 17.92-13.824 47.104-12.288 81.92-55.808 81.92-103.936 0-59.904-48.64-108.544-108.544-108.544-43.008 0-83.456 26.624-99.84 66.56-2.56 7.168-8.192 12.288-15.36 15.36-7.168 2.56-15.36 2.56-22.016-1.536-38.912-19.456-80.384-29.184-123.392-29.184s-84.48 9.728-123.392 29.184c-7.168 2.56-15.36 4.096-22.016 1.536-7.168-2.56-12.288-8.192-15.36-15.36C332.8 229.888 293.888 204.8 249.344 204.8z" p-id="5666"></path></svg>
                  </el-icon>
                  动漫
                </el-dropdown-item>
                <el-dropdown-item divided @click="clickHistory">
                  <el-icon :size="20" color="black" style="margin-right: 4px">
                    <svg t="1707532081623" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10576" width="256" height="256"><path d="M992 541.2c1.5 264.1-210.2 479-473.1 482.7C251.2 1027.7 33 813.5 32 545.7c-0.1-14.5 0.5-28.9 1.8-43.1 4.2-49 45-86.7 94.1-86.7 10.7 0 18.3 10.3 15.3 20.6C133.1 471.2 128 507.2 128 544c0 51.9 10.1 102.2 30.1 149.4 19.3 45.7 47 86.8 82.3 122.1s76.4 63 122.1 82.3c47.3 20 97.6 30.1 149.4 30.1s102.2-10.1 149.4-30.1c45.7-19.3 86.8-47 122.1-82.3s63-76.4 82.3-122.1c20-47.3 30.1-97.6 30.1-149.4s-10.1-102.2-30.1-149.4c-19.3-45.7-47-86.8-82.3-122.1s-76.4-63-122.1-82.3c-47.3-20-97.6-30.1-149.4-30.1s-102.2 10.1-149.4 30.1c-17.9 7.6-35 16.4-51.4 26.5-3.4 2.1-1.9 7.4 2.1 7.4h63.2c4.7 0 9.3 2.1 12.3 5.8l69.7 83.7c2.2 2.6 0.3 6.6-3.1 6.6H256c-70.7 0-128-57.3-128-128V9.7c0-3.6 4.3-5.3 6.8-2.8l84.5 84.5c3 3 4.7 7.1 4.7 11.3v49.4c0 3.3 3.7 5.2 6.3 3.2C309.6 97.8 407.1 63.9 512.5 64c264.4 0.2 478 212.8 479.5 477.2z" p-id="10577" fill="#2c2c2c"></path><path d="M640 356v220c0 53-43 96-96 96H308c-2.2 0-4-1.8-4-4v-88c0-2.2 1.8-4 4-4h204c17.7 0 32-14.3 32-32V356c0-2.2 1.8-4 4-4h88c2.2 0 4 1.8 4 4z" p-id="10578" fill="#2c2c2c"></path></svg>
                  </el-icon>
                  观看历史
                </el-dropdown-item>
              </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: "HeaderBar",
  data() {
    return {
      isLargeScreen: false,
      title: '睿蒙影视 Raymond-TV',
      searchInput: ''
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 1180;
      if (this.isLargeScreen) {
        this.title = '睿蒙影视 Raymond-TV'
      }
      else {
        this.title = '睿蒙影视'
      }
    },
    search() {
      if (this.searchInput.length <= 0) {
        ElMessage.error({message: "请先输入影片名称关键字再进行搜索", duration: 1500})
        return
      }
      location.href = '/search?keyword='+this.searchInput
    },
    clickCategory(id) {
      location.href = '/category?id=' + id;
    },
    clickTv() {
      location.href = '/television'
    },
    clickHistory() {
      location.href = '/history'
    }
  }
}
</script>

<style scoped lang="scss">
.bar {
  display: flex;
  justify-content: space-around;
  align-items: start;
  background-color: #809FD9;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 100%;
    cursor: pointer;
    flex: 0 1 auto;
    margin-left: 15px;

    .title {
      font-weight: bold;
      color: white;
      white-space: nowrap;
      @media (min-width: 800px) {
        font-size: 25px;
      }
      @media (max-width: 799px) {
        font-size: 20px;
      }
      margin-left: 10px;
    }
  }

  .middle {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    flex: 0 1 auto;
    margin-right: 10px;

  }
}
</style>
