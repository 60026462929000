<template>
  <HeaderBar />
  <router-view :key="$route.path" />
</template>

<script>
import {defineComponent} from "vue";
import HeaderBar from "@/components/HeaderBar.vue";
export default defineComponent({
  components: {HeaderBar},
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {
    // 全局背景颜色
    document
        .querySelector("body")
        .setAttribute("style", "background-color: whitesmoke");

    // ElementPlus主题色
    document.body.style.setProperty('--el-color-primary', '#6182c7');
    document.body.style.setProperty('--el-color-primary-light-9', '#F5FBF0');
    document.body.style.setProperty('--el-color-primary-light-3', '#759df1');
  }
})
</script>

<style scoped>

</style>
